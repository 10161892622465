body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#myCarousel {
  overflow: hidden;
}
#myCarousel li button {
  background: gray;
}
#myCarousel .logoName {
  font-size: 40px;
  -webkit-transform: translate(0, 20vh);
          transform: translate(0, 20vh);
  margin: auto;
  text-align: center;
}
#myCarousel .loginContainer {
  background-color: #ffffff;
  position: absolute;
  top: 0%;
  right: 0%;
  width: 30%;
  height: 100%;
}
#myCarousel .loginContainer .inputContainer {
  width: 65%;
  -webkit-transform: translate(0, 30vh);
          transform: translate(0, 30vh);
  margin: auto;
}
#myCarousel .ant-carousel .slick-slide {
  text-align: center;
  height: 100vh;
  line-height: 160px;
  background: white;
  overflow: hidden;
}
#myCarousel .ant-carousel .slick-slide img {
  height: 100vh;
}
#myCarousel .ant-carousel .slick-slide h3 {
  color: #fff;
}
#myCarousel #myicon .icon {
  color: rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 20px;
  margin-left: 5%;
}
#myCarousel #myicon .icon:hover {
  color: #1890ff;
}
#myCarousel .code {
  font-family: Arial;
  font-style: italic;
  color: blue;
  font-size: 24px;
  border: 0;
  padding: 3px 3px;
  letter-spacing: 3px;
  font-weight: bolder;
  float: right;
  cursor: pointer;
  width: 150px;
  height: 39px;
  line-height: 29px;
  text-align: center;
  vertical-align: middle;
  background-color: #D8B7E3;
  display: inline-block;
  margin-top: 10px;
}

#myCarousel {
  overflow: hidden;
}
#myCarousel li button {
  background: gray;
}
#myCarousel .logoName {
  font-size: 40px;
  -webkit-transform: translate(0, 20vh);
          transform: translate(0, 20vh);
  margin: auto;
  text-align: center;
}
#myCarousel .loginContainer {
  background-color: #ffffff;
  position: absolute;
  top: 0%;
  right: 0%;
  width: 30%;
  height: 100%;
}
#myCarousel .loginContainer .inputContainer {
  width: 65%;
  -webkit-transform: translate(0, 30vh);
          transform: translate(0, 30vh);
  margin: auto;
}
#myCarousel .ant-carousel .slick-slide {
  text-align: center;
  height: 100vh;
  line-height: 160px;
  background: white;
  overflow: hidden;
}
#myCarousel .ant-carousel .slick-slide img {
  height: 100vh;
}
#myCarousel .ant-carousel .slick-slide h3 {
  color: #fff;
}
#myCarousel #myicon .icon {
  color: rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 20px;
  margin-left: 5%;
}
#myCarousel #myicon .icon:hover {
  color: #1890ff;
}
#myCarousel .code {
  font-family: Arial;
  font-style: italic;
  color: blue;
  font-size: 24px;
  border: 0;
  padding: 3px 3px;
  letter-spacing: 3px;
  font-weight: bolder;
  float: right;
  cursor: pointer;
  width: 150px;
  height: 39px;
  line-height: 29px;
  text-align: center;
  vertical-align: middle;
  background-color: #D8B7E3;
  display: inline-block;
  margin-top: 10px;
}

